import { useState, useEffect, useContext } from 'react';
import { FRAME_COLOR_IMAGE_LINKS } from '@utils/constants';
import { Button, Loading, Modal, PairLogo, Refresh, SwatchController, TakePhoto } from '@components';
import { ModalContext } from '@components/utils/ModalContext';
import { getBaseName } from '@utils/strings';
import { useDebounce } from '@utils/hooks';
import { VTOProps } from '@ts/index';
import { BASE_NO_PRESCRIPTION_REGEX } from '@utils/constants/base-skus';
import VTOProduct from './VTOProduct';
import VTOButton from './VTOButton';
import { captureVTO, fitmixStyles, fmKey } from './config';
import styles from './VTO.module.scss';


const VTO = ({ FM, state, setState, products, pathInfo, showBaseFrameOption = true, setFrameColor }: VTOProps) => {
	const debouncedCaptureVTO = useDebounce(captureVTO, 1000);

	const vtoParams = {
		apiKey: fmKey,
		onSnapshot: debouncedCaptureVTO,
		onAgreePrivacyTerms: () => setPrivacyTermsAccepted(true),
		css: fitmixStyles,
	};

	const [FitMix, setFitMix] = useState(null);
	const [privacyTermsAccepted, setPrivacyTermsAccepted] = useState(false);

	const productURL = `${pathInfo}/${state.product.handle}?frameColor=${state.variant.option}`;

	const open = useContext(ModalContext);

	useEffect(() => {
		const originalSku = state.variant.sku.match(BASE_NO_PRESCRIPTION_REGEX)[0];
		FitMix && FitMix.setFrame(originalSku);
	}, [FitMix, state]);

	useEffect(() => {
		const widgetCreator = window?.FitMix ?? FM;

		if (!widgetCreator) return;

		if (!FitMix && open) {
			const fmInstance = widgetCreator.createWidget('my-fitmix-container', vtoParams, function() {
				fmInstance.startVto('live');
				setFitMix(fmInstance);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, FM]);

	const closeVTO = () => {
		FitMix.stopVto();
	};

	const getVTOSnapshot = () => {
		FitMix.getSnapshot();
	};

	const resetLiveMode = () => {
		FitMix.restartVto();
	};

	const handleSwatchChange = ({ option, type }) => {
		setState({
			type: 'frame',
			option,
		});
	};

	const handleClose = ({ option, type }) => {
		if (setFrameColor && type === 'frame') {
			setFrameColor(option);
		}
		closeVTO();
	}

	return (
		<div className={styles[`vto-popup`]} data-vto-popup>
			<div className={`${styles['vto-popup__pair-logo']} ${styles['vto-icon']}`}>
				<PairLogo />
			</div>

			<Modal.Close asChild>
				<Button
					extraClasses={`${styles['vto-popup__close']}`}
					label='Close'
					color={'transparent-light'}
					onClick={() => closeVTO()}
				/>
			</Modal.Close>

			{!FitMix && <Loading />}
			<section className={styles['vto-popup__fitmix-container']} id='my-fitmix-container' />

			<section className={styles['relative']}>
				{privacyTermsAccepted && (
					<div className={styles['vto-icon__container']}>
						<VTOButton clickEvent={getVTOSnapshot} text='Take Photo'>
							<TakePhoto />
						</VTOButton>
						<VTOButton clickEvent={resetLiveMode} text='Refresh'>
							<Refresh />
						</VTOButton>
					</div>
				)}

				<div className={styles['select-frame__carousel']}>
					{showBaseFrameOption &&
						products.map(product => (
							<VTOProduct
								key={`${product.name} VTO link`}
								product={product}
								active={state.product.name === product.name}
								vtoState={state}
								setVtoState={setState}
							/>
						))}
				</div>

				<div className={styles['vto-popup__options-container']}>
					<SwatchController
						alignment='center'
						callback={handleSwatchChange}
						name={state.product.name}
						// this removes extra color added for search purposes
						options={Object.keys(FRAME_COLOR_IMAGE_LINKS).filter(color => color !== 'Clear')}
						selected={state.variant.option}
						title='Base Frame Color'
						type='frame'
					/>
					{
						!showBaseFrameOption ? (
							<Modal.Close asChild>
								<Button
									color='green'
									data-name='build-flow'
									data-build-your={state.product.name}
									fullWidth
									label={`Build Your ${getBaseName(state.product.name, false)}`}
									onClick={() => handleClose({ option: state.variant.option, type: 'frame' })}
								/>
							</Modal.Close>
						) : (
							<Button
								color='green'
								data-name='build-flow'
								data-build-your={state.product.name}
								fullWidth
								onClick={closeVTO}
								href={productURL}
								label={`Build Your ${getBaseName(state.product.name, false)}`}
							/>
						)
					}
				</div>
			</section>
		</div>
	);
};

export default VTO;
