import { memo } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import useHasMounted from '@utils/hooks/useHasMounted';
import { useBFContext } from '@context';
import { BUILD_FLOW_STEPS, BUILD_FLOW_STEPS_LENS_EXPANSION, CUSTOMER_JOURNEYS } from '@constants';
import { useIsLensExpansionTest } from '@utils/hooks';
import styles from './BuildFlow.module.scss';

const InteractivePane = dynamic(() => import('./InteractivePane/InteractivePane'));
const ViewPane = dynamic(() => import('./ViewPane/ViewPane'));

export type BuildFlowProps = {
	toggleBuildFlow: ({ type }) => void;
	journey?: string;
	jumpToStep?: boolean;
};

const BuildFlow = () => {
	const { isLensExpansion } = useIsLensExpansionTest();
	const hasMounted = useHasMounted();
	const { step, journey } = useBFContext();
	const isSunglasses = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const bfEnum = isLensExpansion && !isSunglasses ? BUILD_FLOW_STEPS_LENS_EXPANSION : BUILD_FLOW_STEPS;
	const topFrameStep = step === bfEnum.TOP_FRAMES || step === bfEnum.SUBMIT;

	if (!hasMounted) return;

	return (
		<div
			className={cn(styles.container, { [styles['bf-all-tops-container']]: topFrameStep })}
			data-is-lens-expansion={isLensExpansion}
			data-step={step}
		>
			<ViewPane />
			<InteractivePane />
		</div>
	);
};

BuildFlow.displayName = 'BuildFlow';

export default memo(BuildFlow);
